.flecha{
    margin-left: 10px;
    margin-right: 10px;
    }
@media (max-width: 450px) {
.flecha{
margin-left: 10px;
}
.turnosMedicos{
    margin-left: 10px;   
}
.colorLetra{
    color: red;
}
.inicio2{
    border-left: 10px;
}
.linea{
    margin-top: 10px;
    margin-bottom: 15px;
    
    }
}