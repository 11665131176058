.containerPrincipal {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}

.parteDeArriva {

}

.medicoYFlecha {
    display: flex;
    margin-top: 60px;
}

.unTurnoPara {
    float: left;
    
}

.button {
text-align: center;
margin: 20px;
}

.alinearProximos {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.alinearPrevios {
    margin-right: 0%;
}

.dosCards {
    display: inline;
    
}

.containerInicioTurno {
    margin-left: 20px;
    float: left;
}

.unTurnoPara {

}