
.visualizarTurno{
    
    margin-left: 20px;
}

.input{
    align-items: center;
    text-align: justify;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    
}
.preparacion{
    margin-top: 20px;
    margin-bottom: 20px;
}
.inicio{
    margin-left: 15px;
    float: left;
}
.texto{
    margin-left: 20px;
    margin-right: 25px;
}
.proximosTurnos{
    margin-left: 5px;
}
.button{
    text-align: center;
}
/* .linea{
    align-items: center;
    margin-left: 50px;
    margin-right: 50px; 
} */
.turnoCard{
    text-align: left;
    align-items: flex-start;
}
