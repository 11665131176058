.container {
    height: 90vh;
    width: 30vw;
    box-shadow: 0.5px 0px 8px 0px #00000040;
    max-width: 300px;
    min-width: 250px;
}



.menu-container-items {
    padding-top: 30px;
}

.menu-contenedor-item{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    display: flex;

}
.menu-border-active {
    border-left: 6px solid #E4002B;
    padding-left: 24px;
}

.menu-item-text{
padding-left: 12px;
margin: 0;
}