.contenedor-imagen {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: #FFF;
  
}

.boton {
    width: 100%;
    border: 0;
}
.boton:active {
    box-shadow: inset 0px 0px 6px 0px #000;
    border-radius: 10px;
}
.textMenuBoton{
    font-size: medium;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
