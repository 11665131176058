 .turnoPara{
    /* border-radius: 10%;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    padding: 5px;
    width: 150px; */
} 
@media (max-width: 450px) {
.unTurnoPara{
    margin-left: 20px;
    
}
.turnoPara{
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    padding: 5px;
    width: 150px;
}
}