.color {
    color: black;
}

.textInputs {
    padding-top: 6%;
    padding-left: 3%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
    margin: 0;
    text-align: initial;
}

.checkBox {
    font-style: normal;
    font-size: 14px;
    color: #363636;
    margin: 0;
    height: min-content;
}

.textResetPass {
    margin: 0;
    text-decoration: underline;
    color: #e4002b;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.imgLogo {
    padding-left: 25%;
    padding-bottom: 6%;
}

.textCreateAcaunt {

    text-decoration: underline;
    color: #e4002b;
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
}

.textAcaunt {
    padding: 0px 6px;
    font-size: 15px;
}



.imgLogin {

    position: relative;
}

.card-title {
    position: absolute;
    font-size: 1em;
    top: 37%;
    color: white;
    left: 15%;
    font-size: 50px;
}

.card-sub-title {
    position: absolute;
    font-size: 1em;
    top: 53%;
    color: white;
    max-width: 500px;
    left: 15%;
    font-size: 20px;
}

.card-desc {
    position: absolute;
    font-size: .8em;
    top: 63%;
    color: white;
    max-width: 500px;
    left: 15%;
    font-size: 1rem;
}

.card-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.formLogin {
    text-align: center;
    width: 30%;
    padding-top: 6em;
    display: block;
}

.contenedor-img {
    width: 70%;
}

.title {
    margin-top: 25px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #363636;

}

@media only screen and (max-width:640px) {

    .formLogin {
        text-align: center;
        width: 100%;
        padding-top: 0em;
        display: block;
    }

    .contenedor-img {
        width: 0%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .card-sub-title {
        max-width: 280px;
        left: 10%;
        font-size: 19px
    }

    .card-title {
        left: 10%;
        font-size: 47px;
    }

    .card-desc {
        left: 10%;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .card-sub-title {
        max-width: 435px;
        left: 13%;
        font-size: 20px
    }

    .card-title {
        left: 13%;
        font-size: 50px;
    }

    .card-desc {
        left: 13%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .card-title {
        left: 15%;
        font-size: 50px;
    }

    .card-sub-title {
        left: 15%;
        max-width: 500px;
        font-size: 20px
    }

    .card-desc {
        left: 15%;
    }

}