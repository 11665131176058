.unTurnoPara{
    float: right;
    margin-top: 20px;
    margin-left: 20px;
}
.text-volver{
    float: left;
    text-align: left;
    margin-left: 20px;
}
.volverYTurnos{
     columns: 2;
}

.selectEspe{
margin-top: 50px;
margin-left: 20px;

}
.containerCentros{
margin-top: 100px;
margin-left: 20px;
text-align: center;
}
.button-select-espe{
     
    margin-bottom: 20px;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; 
}
.etiquetasP{
    columns: 2;
    margin-top: 40px;
    margin-left: 20px;
    max-width: 58%;
     
    /* padding: 0%; */
}
.especialidadselct{
margin-right: 50px;
}
.tipoDeConsultaSelect{
    float: right;
}



