.step-mobile {
  display: flex;
}

.box-step-mobile {
  background: #CECECE;
  overflow: hidden;
  padding: 4px;
  width: 33%;
  height: 2px;
  background-image: linear-gradient(rgb(229 228 228), #f1f1f1);
}


.box-active-mobile {
  background: #E4002B;
  width: 33%;
}
