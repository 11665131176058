 .containerPrincipal{
    min-height: 80%;
    max-width: 100%;
}

 .card-centro-turno{
    min-height: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding:10px;
     margin:10px 0px; 
    cursor: pointer;
}  

 .card-centro-turno-selected{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding:10px;
    background-color: #70b4a0;
    margin:10px 0px;
    cursor: pointer;
} 
 .text-select-centro{
    margin:0;
}

.sub-text-select-centro{
    margin:0;
} 


 .button-select-centro {
    position: initial;
    width: auto;
    height: auto;
    padding-top:30px;
    margin-right: 30px;
} 
@media only screen and (max-width:640px) {
.button-select-centro {
        position: relative;
       
}
}