.title-detalles-conf{
 
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    color: #363636;
    margin: 0px;
}

.subtitle-detalles-conf{
    color: #363636;
}

.text-name-detalles-conf {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
}

.puesto-name-detalles-conf {
    margin: 2px 0px;
    color: #363636;
}

.data-detalles-conf {
    margin: 5px 0px;
    font-size: 14px;
}