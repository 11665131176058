.icons-profile {
    animation-name: itemsMenu;
    animation-duration: 1000ms;
    animation-direction: alternate;
    animation-iteration-count: 1;
}

@keyframes itemsMenu {
    from {
        transform: translateX(-150px) rotateY(180deg);
    }
    to {
        transform: translateX(0) rotateY(0deg);
    }
}