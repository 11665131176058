.visualizarTurnoDesktop{
    display: inline;
    text-align: left;
    margin-left: 25px;
}
.parteDeArrivaDesktop{
    float: left;
    margin-left: 25px;
}
.unTurnoParaDesktop{
margin-top: 50px;
float: left;
margin-left: 20px;
}
.inputDesktop{
    align-items: center;
    
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    
}
.cardDesktop{
    margin-top: 100px;
    margin-left: 25px;
}
.modalDesktop{
    float: right;
    margin-right: 25px;
    margin-top: 10px;

}
.preparacionDesktop{
    margin-left: 20px;
}
.inicioDesktop{
    margin-left: 15px;
}
.textoDesktop{
    margin-left: 20px;
    margin-right: 25px;
}
.proximosTurnosDesktop{
    margin-left: 5px;
}
.buttonDesktop{
    text-align: center;
    margin-top: 20px;
}
/* .linea{
    align-items: center;
    margin-left: 50px;
    margin-right: 50px; 
} */
.textInputsDesktop{
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
}