.textInputSelect {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #363636;
    margin: 0;
   text-align: initial;
   padding-left: 2px;
}