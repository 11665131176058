 .iconoNota{
   /* margin-left: 180px; */
}
.iconoBasura{
    margin-left: 20px;
} 
@media (max-width: 450px) {
    .iconoNota{
        margin-left: 50px;
     }
     .iconoBasura{
         margin-left: 20px;
     } 
}