.inicio-container {
    height: 100vh;
/*     background-color: #F6F6F6; */
    width: 73%;
    z-index: -2

}
.inicio-container-card {
    padding-top: 4%;
    padding-left: 2%;
    padding-right: 2%;
    width: 50%;
/*     background-color: #F6F6F6; */
    height: 200px;
}
.inicio-container-cards {
    display: flex;
}
.text-bienv{
    text-align: center;
    color: #363636cc;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    padding-top: 16px;

}



@media only screen and (max-width:640px) {
  
    .inicio-container-cards {
        display: inline;
    }
    .inicio-container-card {
     

        padding-right: 10%;
        width: 96%;
   /*      background-color: #F6F6F6; */
        height: 300px;
    }
    .inicio-container {
        /* height: 100vh; */
 /*        background-color: #F6F6F6; */
       /*  width: 73%; */
        z-index: -2
    
    }

    
}
