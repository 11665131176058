.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

.text-name-centro{
    color: red;
    font-weight: 400;
    margin:5px 0px
}

.hora-turno-card{
    margin:10px;
    padding: 5px;
    border-radius: 20px;
    text-align: center;
}
