.textInputs-register {
  padding-top: 2.4%;
  padding-left: 3%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: initial;
  color: #363636
}

.step {
  margin: 5px;
  width: 310px
}

@media only screen and (min-width: 768px) {
  .float-lower-left {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .float-lower-left {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .float-lower-left {
    width: 33%;
  }
}