.containerPrincipal{
    max-width: 90%;
}
.modalFiltro{
    margin-top: 20px;
}
.text-turno-fecha{
    font-size: 14px;
    font-weight: 500;
    color: #363636;
}

.subText-turno-fecha{
    color: #E4002B;
    font-size: 14px;
    padding: 0px 10px 0px 5px;
    font-weight: 600;
}

.text-volver-mobile-turno-disable{
    margin: 0px;
    color: #CECECE;
    /* float: left; */
    
}
.text-volver-mobile-turno {
    margin: 1px 0px;
   /*  float: left; */
    font-weight: 500;
    color: #363636;
}


.turno-button-back{
    /* display: flex;
    background-color: #fff;
    margin: 0px 20px;
    position: inherit;
    left: 0px; */
    margin-top: 50px;
}

.turno-button-next{
    /* display: flex; */
    /* position: absolute;
    right:0px; */
    /* margin: 0px 20px; */
  margin-right: 500px;
  margin-bottom: 20px;
}

/* .container-horarios-turnos{
    margin: 0px 20px;
    padding: 10px;
    max-height: 415px;
    overflow: scroll;
} */

.text-name-presta{
    font-weight: 600;
    margin:10px 0px 5px 0px ;
}

.text-siguiente-mobile {
    /* text-align: left; */
    color: #363636;
    font-weight: 500;
    cursor: pointer;
    padding-left: 30px;
}

/* .container-card-turno {
    border:1px solid #e7e7e7;
    border-radius:10px;
    margin:10px; 
    padding:10px
} */