.turnoCard{
  max-width: 100%;
   
}
.turnoCardNomDoc{
    font-weight: 650;
    font-size: medium;
     margin: 0;
     padding: 0;
}
.iconos{
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}
@media (max-width: 450px) {
.turnoCard{
     text-align: left;
    color: #363636;
    margin-left: 25px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    width: 298px;
    /* margin: 5px 25px; */
    height: 200px;
    overflow: hidden; 
    
    /* text-align: flex;  */
}
.iconos{
    float: right;
}
.turnoCardNomDoc{
    
    /* font-weight: 550; */
     font-size: 15px; 
    text-align: left;
     margin: 0;
     padding: 0;
}
.linea{
margin-top: 10px;
margin-bottom: 15px;
}
.margenes{
    /* align-items: center; */
    text-align: left;
    margin: 0;
    padding: 0;
}
 /* .contenedorTexto{
    margin-bottom: 40px;
}  */
}