.proximosTurnos{
    /* margin-left: 20px; */
}
.medico{
    /* margin-right: 10px; */
}


@media (max-width: 450px) {
.button{
    font-weight: 600;
}
.medico{
    margin-top: 25px;
    margin-right: 15px;
    margin-left: 30px;
    margin-bottom: 0px;
}
.turnos{
    margin-top: 40px;
    margin-bottom: 10px;
}
.noHayTurnos{
    margin-left: 100px;
}
}