/* html {
  height: 100%;
}
body {
  min-height: 100%;
} */

.containerHomeClientes {
    display: flex;
    /* min-height: 80%; */
}

.homeClientes-screem {
  left: 50%;
  top: 15%;
  position: absolute;
  /* min-width: 340px; */
}
@media only screen and (max-width:640px) {
  .containerHomeClientes {
      display: inline;
  }
  .homeClientes-screem {
    position: initial;
  }
}