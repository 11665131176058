
  .title-active{
    color: #E4002B;

  }

  .title-step {
    color: #CECECE;
    margin-bottom: 2px;
    font-size: 14px;
    white-space: nowrap;
  }

  .box-step {
    background: #CECECE;
    overflow: hidden;
    padding: 4px;
    
  }

  .box-step {
    width: 95%;
  }


  .box-active{
    background: #E4002B;
  }