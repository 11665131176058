@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-container {
    padding-top: 150px;
    text-align: center
}
.textInputSelect {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #363636;
    margin: 0;
   text-align: initial;
   padding-left: 2px;
}
.color {
    color: black;
}

.textInputs {
    padding-top: 6%;
    padding-left: 3%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
    margin: 0;
    text-align: initial;
}

.checkBox {
    font-style: normal;
    font-size: 14px;
    color: #363636;
    margin: 0;
    height: min-content;
}

.textResetPass {
    margin: 0;
    text-decoration: underline;
    color: #e4002b;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.imgLogo {
    padding-left: 25%;
    padding-bottom: 6%;
}

.textCreateAcaunt {

    text-decoration: underline;
    color: #e4002b;
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
}

.textAcaunt {
    padding: 0px 6px;
    font-size: 15px;
}



.imgLogin {

    position: relative;
}

.card-title {
    position: absolute;
    font-size: 1em;
    top: 37%;
    color: white;
    left: 15%;
    font-size: 50px;
}

.card-sub-title {
    position: absolute;
    font-size: 1em;
    top: 53%;
    color: white;
    max-width: 500px;
    left: 15%;
    font-size: 20px;
}

.card-desc {
    position: absolute;
    font-size: .8em;
    top: 63%;
    color: white;
    max-width: 500px;
    left: 15%;
    font-size: 1rem;
}

.card-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.formLogin {
    text-align: center;
    width: 30%;
    padding-top: 6em;
    display: block;
}

.contenedor-img {
    width: 70%;
}

.title {
    margin-top: 25px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #363636;

}

@media only screen and (max-width:640px) {

    .formLogin {
        text-align: center;
        width: 100%;
        padding-top: 0em;
        display: block;
    }

    .contenedor-img {
        width: 0%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .card-sub-title {
        max-width: 280px;
        left: 10%;
        font-size: 19px
    }

    .card-title {
        left: 10%;
        font-size: 47px;
    }

    .card-desc {
        left: 10%;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .card-sub-title {
        max-width: 435px;
        left: 13%;
        font-size: 20px
    }

    .card-title {
        left: 13%;
        font-size: 50px;
    }

    .card-desc {
        left: 13%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .card-title {
        left: 15%;
        font-size: 50px;
    }

    .card-sub-title {
        left: 15%;
        max-width: 500px;
        font-size: 20px
    }

    .card-desc {
        left: 15%;
    }

}
.registracion-text {
  
   
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #363636;
    margin: 0;
    text-align: initial;
    margin-right: 10px;

}

.img-header-clientes{
    padding:"30px"
}




  .title-active{
    color: #E4002B;

  }

  .title-step {
    color: #CECECE;
    margin-bottom: 2px;
    font-size: 14px;
    white-space: nowrap;
  }

  .box-step {
    background: #CECECE;
    overflow: hidden;
    padding: 4px;
    
  }

  .box-step {
    width: 95%;
  }


  .box-active{
    background: #E4002B;
  }
.step-mobile {
  display: flex;
}

.box-step-mobile {
  background: #CECECE;
  overflow: hidden;
  padding: 4px;
  width: 33%;
  height: 2px;
  background-image: linear-gradient(rgb(229 228 228), #f1f1f1);
}


.box-active-mobile {
  background: #E4002B;
  width: 33%;
}

.textInputs-register {
  padding-top: 2.4%;
  padding-left: 3%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: initial;
  color: #363636
}

.step {
  margin: 5px;
  width: 310px
}

@media only screen and (min-width: 768px) {
  .float-lower-left {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .float-lower-left {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .float-lower-left {
    width: 33%;
  }
}
.close {
    right: 10px;
    top: 10px;
    position: inherit;
    border: 0px;
}
.container {
    height: 90vh;
    width: 30vw;
    box-shadow: 0.5px 0px 8px 0px #00000040;
    max-width: 300px;
    min-width: 250px;
}



.menu-container-items {
    padding-top: 30px;
}

.menu-contenedor-item{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    display: flex;

}
.menu-border-active {
    border-left: 6px solid #E4002B;
    padding-left: 24px;
}

.menu-item-text{
padding-left: 12px;
margin: 0;
}
.inicio-container {
    height: 100vh;
/*     background-color: #F6F6F6; */
    width: 73%;
    z-index: -2

}
.inicio-container-card {
    padding-top: 4%;
    padding-left: 2%;
    padding-right: 2%;
    width: 50%;
/*     background-color: #F6F6F6; */
    height: 200px;
}
.inicio-container-cards {
    display: flex;
}
.text-bienv{
    text-align: center;
    color: #363636cc;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    padding-top: 16px;

}



@media only screen and (max-width:640px) {
  
    .inicio-container-cards {
        display: inline;
    }
    .inicio-container-card {
     

        padding-right: 10%;
        width: 96%;
   /*      background-color: #F6F6F6; */
        height: 300px;
    }
    .inicio-container {
        /* height: 100vh; */
 /*        background-color: #F6F6F6; */
       /*  width: 73%; */
        z-index: -2
    
    }

    
}

.contenedor-imagen {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: #FFF;
  
}

.boton {
    width: 100%;
    border: 0;
}
.boton:active {
    box-shadow: inset 0px 0px 6px 0px #000;
    border-radius: 10px;
}
.textMenuBoton{
    font-size: medium;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}


.loader {
    color: #e61212;
    font-size: 8px;
    width: 10px;
    height: 10px;
    z-index: 100;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
  }
  
  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 
      2em -2em 0 0em, 3em 0 0 -1em, 
      2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
      3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 
      2em -2em 0 0, 3em 0 0 0.2em, 
      2em 2em 0 0, 0 3em 0 -1em, 
      -2em 2em 0 -1em, -3em 0 0 -1em, 
      -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
       -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
       -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
       3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
       -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
      3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
    
.botonCamara {
    height: 100px;
    border-color: rgba(136, 119, 136, 0.467);
    border-style: dotted;
    text-align: center;
    padding-top: 40px;
    justify-content: center;
    margin-top: 50px;
    border-radius: 10px;
}
.input-image {
    font-family: sans-serif;
    text-align: center;
  }
  
 .selectEspe{
  
}
.info-mobile{
  
}
.selects{
    
}

.button-select-espe{
   max-width: 30%;
   float: right;
} 


@media only screen and (max-width:400px) {
    .selectEspe{
  margin-left: 20px;
    }
    .button-select-espe{
        float: right;
        margin-right: 20%;
        max-width: 80%;
        /* position: absolute;
        bottom: 51px;
        width: 90%;
        height: 50px; */
    }
}

 .containerPrincipal{
    min-height: 80%;
    max-width: 100%;
}

 .card-centro-turno{
    min-height: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding:10px;
     margin:10px 0px; 
    cursor: pointer;
}  

 .card-centro-turno-selected{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding:10px;
    background-color: #70b4a0;
    margin:10px 0px;
    cursor: pointer;
} 
 .text-select-centro{
    margin:0;
}

.sub-text-select-centro{
    margin:0;
} 


 .button-select-centro {
    position: initial;
    width: auto;
    height: auto;
    padding-top:30px;
    margin-right: 30px;
} 
@media only screen and (max-width:640px) {
.button-select-centro {
        position: relative;
       
}
}

.text-turno-fecha{
    font-size: 14px;
    font-weight: 500;
    color: #363636;
}

.subText-turno-fecha{
    color: #E4002B;
    font-size: 14px;
    padding: 0px 10px 0px 5px;
    font-weight: 600;
}

.text-volver-mobile-turno-disable{
    margin: 0px;
    color: #CECECE;
    float: left;
    
}
.text-volver-mobile-turno {
    margin: 1px 0px;
    float: left;
    font-weight: 500;
    color: #363636;
}


.turno-button-back{
    display: flex;
    background-color: #fff;
    margin: 0px 20px;
    position: inherit;
    left: 0px;
}

.turno-button-next{
    display: flex;
    position: absolute;
    right:0px;
    margin: 0px 20px;
  
}

.container-horarios-turnos{
    margin: 0px 20px;
    padding: 10px;
    max-height: 415px;
    overflow: scroll;
}

.text-name-presta{
    font-weight: 600;
    margin:10px 0px 5px 0px ;
}

.text-siguiente-mobile {
    text-align: left;
    color: #363636;
    font-weight: 500;
    cursor: pointer;
    padding-left: 30px;
}

.container-card-turno {
    border:1px solid #e7e7e7;
    border-radius:10px;
    margin:10px; 
    padding:10px
}


.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

.text-name-centro{
    color: red;
    font-weight: 400;
    margin:5px 0px
}

.hora-turno-card{
    margin:10px;
    padding: 5px;
    border-radius: 20px;
    text-align: center;
}

.close {
    right: 10px;
    top: 10px;
    position: inherit;
    border: 0px;
}
.title-detalles-conf{
 
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    color: #363636;
    margin: 0px;
}

.subtitle-detalles-conf{
    color: #363636;
}

.text-name-detalles-conf {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
}

.puesto-name-detalles-conf {
    margin: 2px 0px;
    color: #363636;
}

.data-detalles-conf {
    margin: 5px 0px;
    font-size: 14px;
}
.unTurnoPara{
    float: right;
    margin-top: 20px;
    margin-left: 20px;
}
.text-volver{
    float: left;
    text-align: left;
    margin-left: 20px;
}
.volverYTurnos{
     columns: 2;
}

.selectEspe{
margin-top: 50px;
margin-left: 20px;

}
.containerCentros{
margin-top: 100px;
margin-left: 20px;
text-align: center;
}
.button-select-espe{
     
    margin-bottom: 20px;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; 
}
.etiquetasP{
    columns: 2;
    margin-top: 40px;
    margin-left: 20px;
    max-width: 58%;
     
    /* padding: 0%; */
}
.especialidadselct{
margin-right: 50px;
}
.tipoDeConsultaSelect{
    float: right;
}




.containerPrincipal{
    max-width: 90%;
}
.modalFiltro{
    margin-top: 20px;
}
.text-turno-fecha{
    font-size: 14px;
    font-weight: 500;
    color: #363636;
}

.subText-turno-fecha{
    color: #E4002B;
    font-size: 14px;
    padding: 0px 10px 0px 5px;
    font-weight: 600;
}

.text-volver-mobile-turno-disable{
    margin: 0px;
    color: #CECECE;
    /* float: left; */
    
}
.text-volver-mobile-turno {
    margin: 1px 0px;
   /*  float: left; */
    font-weight: 500;
    color: #363636;
}


.turno-button-back{
    /* display: flex;
    background-color: #fff;
    margin: 0px 20px;
    position: inherit;
    left: 0px; */
    margin-top: 50px;
}

.turno-button-next{
    /* display: flex; */
    /* position: absolute;
    right:0px; */
    /* margin: 0px 20px; */
  margin-right: 500px;
  margin-bottom: 20px;
}

/* .container-horarios-turnos{
    margin: 0px 20px;
    padding: 10px;
    max-height: 415px;
    overflow: scroll;
} */

.text-name-presta{
    font-weight: 600;
    margin:10px 0px 5px 0px ;
}

.text-siguiente-mobile {
    /* text-align: left; */
    color: #363636;
    font-weight: 500;
    cursor: pointer;
    padding-left: 30px;
}

/* .container-card-turno {
    border:1px solid #e7e7e7;
    border-radius:10px;
    margin:10px; 
    padding:10px
} */
 .turnoPara{
    /* border-radius: 10%;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    padding: 5px;
    width: 150px; */
} 
@media (max-width: 450px) {
.unTurnoPara{
    margin-left: 20px;
    
}
.turnoPara{
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    padding: 5px;
    width: 150px;
}
}
 .iconoNota{
   /* margin-left: 180px; */
}
.iconoBasura{
    margin-left: 20px;
} 
@media (max-width: 450px) {
    .iconoNota{
        margin-left: 50px;
     }
     .iconoBasura{
         margin-left: 20px;
     } 
}
.turnoCard{
  max-width: 100%;
   
}
.turnoCardNomDoc{
    font-weight: 650;
    font-size: medium;
     margin: 0;
     padding: 0;
}
.iconos{
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}
@media (max-width: 450px) {
.turnoCard{
     text-align: left;
    color: #363636;
    margin-left: 25px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    width: 298px;
    /* margin: 5px 25px; */
    height: 200px;
    overflow: hidden; 
    
    /* text-align: flex;  */
}
.iconos{
    float: right;
}
.turnoCardNomDoc{
    
    /* font-weight: 550; */
     font-size: 15px; 
    text-align: left;
     margin: 0;
     padding: 0;
}
.linea{
margin-top: 10px;
margin-bottom: 15px;
}
.margenes{
    /* align-items: center; */
    text-align: left;
    margin: 0;
    padding: 0;
}
 /* .contenedorTexto{
    margin-bottom: 40px;
}  */
}
.containerPrincipal {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}

.parteDeArriva {

}

.medicoYFlecha {
    display: flex;
    margin-top: 60px;
}

.unTurnoPara {
    float: left;
    
}

.button {
text-align: center;
margin: 20px;
}

.alinearProximos {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.alinearPrevios {
    margin-right: 0%;
}

.dosCards {
    display: inline;
    
}

.containerInicioTurno {
    margin-left: 20px;
    float: left;
}

.unTurnoPara {

}
.flecha{
    margin-left: 10px;
    margin-right: 10px;
    }
@media (max-width: 450px) {
.flecha{
margin-left: 10px;
}
.turnosMedicos{
    margin-left: 10px;   
}
.colorLetra{
    color: red;
}
.inicio2{
    border-left: 10px;
}
.linea{
    margin-top: 10px;
    margin-bottom: 15px;
    
    }
}
.containerPrincipalDesktop{
    /* margin-left: 20px;
    margin-right: 20px; */
    margin-top: 10px;
}
.containerInicioTurnoDesktop{
/* float: left; */
margin-right: 20px;
}
.unTurnoParaDesktop{
   /* float: right; */
   /* margin-bottom: 50px; */
}
.dosCardsDesktop{
/* display: flex;*/
margin-top: 100px;

}
.alinearProximosDesktop{
    float: left;
    margin-top: 25px;
    max-width: 44%;
    margin-left: 5%;
}
.alinearPreviosDesktop{
    
   margin-right: 5%;
    float: right;
    max-width: 44%;
}
.button {
    /* float: left; */
    }
.flechaRojaDesktop{
    /* margin-left: 300px; */
}
.flechaRoja2Desktop{
    /* margin-left: 100px; */
}
.medicoYFlechaDesktop{
    display: flex;  
}
.proximosTurnos{
    /* margin-left: 20px; */
}
.medico{
    /* margin-right: 10px; */
}


@media (max-width: 450px) {
.button{
    font-weight: 600;
}
.medico{
    margin-top: 25px;
    margin-right: 15px;
    margin-left: 30px;
    margin-bottom: 0px;
}
.turnos{
    margin-top: 40px;
    margin-bottom: 10px;
}
.noHayTurnos{
    margin-left: 100px;
}
}
.turnosPrevios{
    /* margin-left: 20px;
    margin-top: 30px; */
}
.medico{
    /* margin-right: 10px; */
}

@media (max-width: 450px) {
.turnosPrevios{
    display: inline;
    }
    .button{
        font-weight: 600;
    }
    .medico{
        margin-top: 25px;
        margin-right: 15px;
        margin-left: 30px;
        margin-bottom: 0px;
    }
    .turnos{
        margin-top: 40px;
        margin-bottom: 10px;
    }
}



.visualizarTurno{
    
    margin-left: 20px;
}

.input{
    align-items: center;
    text-align: justify;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    
}
.preparacion{
    margin-top: 20px;
    margin-bottom: 20px;
}
.inicio{
    margin-left: 15px;
    float: left;
}
.texto{
    margin-left: 20px;
    margin-right: 25px;
}
.proximosTurnos{
    margin-left: 5px;
}
.button{
    text-align: center;
}
/* .linea{
    align-items: center;
    margin-left: 50px;
    margin-right: 50px; 
} */
.turnoCard{
    text-align: left;
    align-items: flex-start;
}

.visualizarTurnoDesktop{
    display: inline;
    text-align: left;
    margin-left: 25px;
}
.parteDeArrivaDesktop{
    float: left;
    margin-left: 25px;
}
.unTurnoParaDesktop{
margin-top: 50px;
float: left;
margin-left: 20px;
}
.inputDesktop{
    align-items: center;
    
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    
}
.cardDesktop{
    margin-top: 100px;
    margin-left: 25px;
}
.modalDesktop{
    float: right;
    margin-right: 25px;
    margin-top: 10px;

}
.preparacionDesktop{
    margin-left: 20px;
}
.inicioDesktop{
    margin-left: 15px;
}
.textoDesktop{
    margin-left: 20px;
    margin-right: 25px;
}
.proximosTurnosDesktop{
    margin-left: 5px;
}
.buttonDesktop{
    text-align: center;
    margin-top: 20px;
}
/* .linea{
    align-items: center;
    margin-left: 50px;
    margin-right: 50px; 
} */
.textInputsDesktop{
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
}
/* html {
  height: 100%;
}
body {
  min-height: 100%;
} */

.containerHomeClientes {
    display: flex;
    /* min-height: 80%; */
}

.homeClientes-screem {
  left: 50%;
  top: 15%;
  position: absolute;
  /* min-width: 340px; */
}
@media only screen and (max-width:640px) {
  .containerHomeClientes {
      display: inline;
  }
  .homeClientes-screem {
    position: initial;
  }
}
.icons-profile {
    animation-name: itemsMenu;
    animation-duration: 1000ms;
    animation-direction: alternate;
    animation-iteration-count: 1;
}

@keyframes itemsMenu {
    from {
        transform: translateX(-150px) rotateY(180deg);
    }
    to {
        transform: translateX(0) rotateY(0deg);
    }
}
.App * {
    font-family: 'Lato', sans-serif;
}





