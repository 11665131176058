.turnosPrevios{
    /* margin-left: 20px;
    margin-top: 30px; */
}
.medico{
    /* margin-right: 10px; */
}

@media (max-width: 450px) {
.turnosPrevios{
    display: inline;
    }
    .button{
        font-weight: 600;
    }
    .medico{
        margin-top: 25px;
        margin-right: 15px;
        margin-left: 30px;
        margin-bottom: 0px;
    }
    .turnos{
        margin-top: 40px;
        margin-bottom: 10px;
    }
}

