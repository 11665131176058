.containerPrincipalDesktop{
    /* margin-left: 20px;
    margin-right: 20px; */
    margin-top: 10px;
}
.containerInicioTurnoDesktop{
/* float: left; */
margin-right: 20px;
}
.unTurnoParaDesktop{
   /* float: right; */
   /* margin-bottom: 50px; */
}
.dosCardsDesktop{
/* display: flex;*/
margin-top: 100px;

}
.alinearProximosDesktop{
    float: left;
    margin-top: 25px;
    max-width: 44%;
    margin-left: 5%;
}
.alinearPreviosDesktop{
    
   margin-right: 5%;
    float: right;
    max-width: 44%;
}
.button {
    /* float: left; */
    }
.flechaRojaDesktop{
    /* margin-left: 300px; */
}
.flechaRoja2Desktop{
    /* margin-left: 100px; */
}
.medicoYFlechaDesktop{
    display: flex;  
}