.registracion-text {
  
   
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #363636;
    margin: 0;
    text-align: initial;
    margin-right: 10px;

}

.img-header-clientes{
    padding:"30px"
}


